import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"

import "./header.css"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "rough_transparant_banner.png" }) {
        childImageSharp {
          fixed(width: 236, height: 78) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <header className="header_header">
      <div className="header_div">
        <Link to="/">
          <Img fixed={data.file.childImageSharp.fixed}/>
        </Link>
        <a className="header_a" href="/articles">Articles</a>
        <a className="header_a" href="/aboutus">About Us</a>
        <div className="dropdown">
          <span><i className="arrow"></i></span>
          <div className="dropdown-content">
            <a className="dropdown_a" href="/articles">Articles</a>
            <a className="dropdown_a" href="/aboutus">About Us</a>
          </div>
        </div> 
      </div>
    </header>
  );
}

export default Header
