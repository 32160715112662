import React from "react"

import "./site_footer.css"

export default (props) => {
    return (
      <footer className='site_footer'>
        <form class="email-form" name="newsletter" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/emailsignup">
          <input type="hidden" name="form-name" value="newsletter"/>
          <div hidden aria-hidden="true">
            <label>
            Don’t fill this out if you're human: 
              <input name="bot-field" />
            </label>
          </div>
          <label className="email-label">Don't miss an article</label>
          <div className="email-holder">
            <input className="email-input" type="text" name="name" placeholder="Name"  id="name" required />
            <input className="email-input" type="email" name="email" placeholder="Email"  id="email" required />
            <button className="email-submit" type="submit">Subscribe</button>
          </div>
        </form>
        <div className="social-holder">
          <a className="social-button"
            href="https://www.facebook.com/MathMouth-103217664650161/"
            target="_blank">
            <i class="fa fa-facebook align-middle"></i>
          </a>
          <a className="social-button"
            href="https://twitter.com/math_mouth"
            target="_blank">
            <i class="fa fa-twitter align-middle"></i>
          </a>
        </div>
        © {new Date().getFullYear()}, Built by Alex and Jack Strang
      </footer>
    );
  }